<template>
  <div
    v-if="schedules.length != 0"
    class="card py-3 px-4 card-citmer cardRadius mb-5">
    <div class="d-flex">
      <div class="titles col-10">Servicios</div>
    </div>
    <hr />
    <div class="row px-3 pb-4">
      <label for="inputEmail3" class="col-md-2 col-xl-1 col-form-label"
        >Agendas</label
      >
      <div class="col-md-5">
        <select
          v-model="agenda_id"
          @change="getServices"
          class="rounded form-control form-select text-center radiusButton"
          aria-label=".form-select-lg example">
          <option
            v-for="schedule in schedules"
            :key="schedule.id"
            :value="schedule.id"
            v-text="schedule.name"></option>
        </select>
      </div>
      <div class="col-md-5 col-12">
        <b-button
          class="col-12 col-sm-5 mt-md-0 mt-3"
          variant="primary"
          @click="$store.getters.getModal('addNewService').show()"
          >Nuevo servicio</b-button
        >
      </div>
    </div>
    <b-table
      responsive
      :items="services"
      :fields="fieldsTable"
      :table-class="'patientsHeadClass'"
      striped
      small
      borderless>
      <!-- SERVICE NAME -->
      <template v-slot:cell(name)="row">
        <input
          v-model="row.item.name"
          :disabled="!row.item.editService"
          class="text-primary fw-bold text-center form-control" />
      </template>
      <!-- SERVICE NAME / -->
      <!-- SERVICE DURATION -->
      <template v-slot:cell(duration)="row">
        <select
          v-model="row.item.duration"
          :disabled="!row.item.editService"
          class="rounded form-control form-select text-center radiusButton"
          aria-label=".form-select-lg example">
          <option
            v-for="minutes in minutesList"
            :key="minutes.quantity"
            :value="minutes.quantity"
            v-text="minutes.label"></option>
        </select>
      </template>
      <!-- SERVICE DURATION / -->
      <!-- SERVICE COST -->
      <template v-slot:cell(cost)="row">
        <input
          v-model="row.item.cost"
          :disabled="!row.item.editService"
          @keypress="isNumber($event)"
          type="number"
          class="text-primary fw-bold text-center form-control" />
      </template>
      <!-- SERVICE COST / -->
      <!-- ACTIONS -->
      <template v-slot:cell(actions)="row">
        <template v-if="row.item.editService">
          <font-awesome-icon
            @click="
              getSchedules();
              row.item.editService = false;
            "
            class="tableIcon mt-1 me-4 text-primary"
            :icon="['fas', 'times-circle']" />
          <font-awesome-icon
            @click="putServices(row.item)"
            class="tableIcon mt-1 me-4 text-primary"
            :icon="['fas', 'save']" />
        </template>
        <font-awesome-icon
          v-if="row.item.editService"
          @click="confirmDeleteService(row.item.id)"
          class="tableIcon mt-3 text-primary"
          :icon="['fa', 'trash-alt']" />
        <font-awesome-icon
          v-if="!row.item.editService"
          @click="row.item.editService = true"
          class="tableIcon mt-2 text-primary"
          :icon="['fas', 'edit']" />
      </template>
      <!-- ACTIONS / -->
    </b-table>
    <NewService :agenda="agenda" @reloadList="getServices" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { minutesList } from "@/constants/settings";
import NewService from "@/components/settings/NewService.vue";

export default {
  name: "SettingsServices",
  components: {
    NewService,
  },
  data() {
    return {
      schedules: [],
      agenda_id: "",
      minutesList,
      services: [],
      fieldsTable: [
        { class: "text-center", key: "name", label: "Servicio" },
        { class: "text-center mxw-8", key: "duration", label: "Duración" },
        { class: "text-center mxw-8", key: "cost", label: "Costo" },
        { class: "text-center mxw-12", key: "actions", label: "Acciones" },
      ],
    };
  },
  created() {
    this.getSchedules();
  },
  computed: {
    agenda() {
      const scheduleSelected = this.schedules.find(
        (schedule) => schedule.id === this.agenda_id
      );
      return scheduleSelected;
    },
  },
  methods: {
    ...mapActions(["updateConfirmData"]),
    confirmDeleteService(id) {
      this.updateConfirmData({
        title: "Remover servicio",
        description: "¿Esta seguro de querer borrar este servicio?",
        params: id,
        confirmFunction: this.deleteServices,
      });
      this.$store.getters.getModal("confirmationModal").show();
    },
    isNumber(evt) {
      const event = evt || window.event;
      const charCode = event.which ? event.which : event.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.preventDefault();
      } else {
        return true;
      }
      return "";
    },
    getServices() {
      this.$api.settings
        .getServices({ agenda_id: this.agenda_id })
        .then((res) => {
          this.services = res.data.services;
          this.services.forEach((service) => {
            this.$set(service, "editService", false);
          });
        });
    },
    getSchedules() {
      this.$api.settings.getSchedule().then((res) => {
        this.schedules = res.data.agendas;
        // eslint-disable-next-line prefer-destructuring
        this.agenda_id = this.schedules[0].id;
        this.getServices();
      });
    },
    putServices(service) {
      const payload = {
        name: service.name,
        cost: service.cost,
        duration: service.duration,
      };
      this.$api.settings.putServices(service.id, payload).then(() => {
        this.getServices();
      });
    },
    deleteServices(id) {
      this.$api.settings.removeService(id).then(() => {
        this.getServices();
      });
    },
  },
};
</script>
