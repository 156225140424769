<template>
  <div class="col-11">
    <SettingsPreferences
      @getCalendarConfig="getCalendarConfig"
      :calendarConfig="calendarConfig"
      class="mb-5" />
    <SettingsCacit
      @getCalendarConfig="getCalendarConfig"
      :calendarConfig="calendarConfig"
      class="mb-5" />
    <SettingsSchedules
      ref="SettingsSchedules"
      class="mb-5"
      @getSchedules="getSchedules" />
    <SettingsServices ref="SettingsServices" />
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-destructuring */

import SettingsPreferences from "@/components/settings/SettingsPreferences.vue";
import SettingsCacit from "@/components/settings/SettingsCacit.vue";
import SettingsServices from "@/components/settings/SettingsServices.vue";
import SettingsSchedules from "@/components/settings/SettingsSchedules.vue";

export default {
  name: "CitSettings",
  components: {
    SettingsPreferences,
    SettingsCacit,
    SettingsServices,
    SettingsSchedules,
  },
  data() {
    return {
      calendarConfig: {},
    };
  },
  mounted() {
    this.getCalendarConfig(this.clinicId);
  },
  watch: {
    clinicId(newId) {
      this.getCalendarConfig(newId);
    },
  },
  methods: {
    getCalendarConfig(id) {
      this.$api.calendar.getConfig({ clinic_id: id }).then((res) => {
        this.calendarConfig = res.data;
      });
    },
    getSchedules() {
      this.$refs.SettingsServices.getSchedules();
    },
    openPatientModal() {
      this.$store.getters.getModal("patientModal").show();
    },
  },
  computed: {
    clinicId() {
      return parseInt(this.$store.getters.getClinic, 10);
    },
  },
};
</script>
