<template>
  <div class="card py-3 px-4 card-citmer cardRadius">
    <template v-if="schedules.length != 0 && !addSchedule">
      <div class="d-flex">
        <div class="col-10 titles">Agendas</div>
        <div
          class="
            text-center
            col-2
            text-primary
            align-content-center
            d-flex
            justify-content-center
          ">
          <span
            v-if="!addSchedule"
            @click="ableAdd"
            class="text-center fw-bold pointer fs-4"
            ><font-awesome-icon :icon="['fas', 'plus']"
          /></span>
        </div>
      </div>
      <hr />
      <b-table
        :items="schedules"
        responsive
        :fields="agendaFields"
        class="fs-5"
        :table-class="'patientsHeadClass'"
        striped
        borderless>
        <template v-slot:cell(name)="row">
          <div class="d-flex justify-content-center align-items-center">
            <template v-if="!row.item.editSchedule">
              <div><span v-text="row.item.name"></span></div>
            </template>
            <template v-else>
              <div class="maxW16">
                <b-form-input
                  v-model="name"
                  :state="!$v.name.$invalid"
                  class="text-primary fw-bold text-center form-control" />
              </div>
            </template>
          </div>
        </template>
        <template v-slot:cell(agenda_users)="row">
          <div class="d-flex justify-content-center align-items-center">
            <template v-if="!row.item.editSchedule">
              <div>
                <p
                  class="text-start"
                  v-for="(au, index) in spliceAgendaUsers(
                    row.item.agenda_users
                  )"
                  :key="au + index"
                  v-text="turnValue(au).join()"></p>
              </div>
            </template>
            <template v-else>
              <div
                class="minW12 maxW23"
                :class="[
                  $v.agenda_type.$invalid ? 'warningGeneralBorder' : '',
                ]">
                <MultiSelect
                  :multiple="true"
                  v-model="agenda_type"
                  :options="agendaTypes"
                  track-by="name"
                  label="name"
                  value="value"
                  placeholder="Agendas" />
              </div>
            </template>
          </div>
        </template>
        <template v-slot:cell(preview)="row">
          <div class="d-flex justify-content-center">
            <template v-if="!row.item.editSchedule">
              <div
                class="
                  vuecal__event
                  d-flex
                  text-white
                  align-items-center
                  justify-content-center
                  previewColor
                "
                :style="'background-color:' + row.item.color">
                <span v-text="row.item.name"></span>
              </div>
            </template>
            <template v-else>
              <div>
                <v-input-colorpicker v-model="color" class="col-12" />
              </div>
            </template>
          </div>
        </template>
        <template v-slot:cell(actions)="row">
          <div>
            <template v-if="!row.item.editSchedule">
              <div class="minW5">
                <font-awesome-icon
                  class="tableIcon me-2"
                  @click="
                    ableEdit(row.item);
                    row.item.editSchedule = true;
                  "
                  :icon="['fa', 'pencil-alt']" />
                <font-awesome-icon
                  class="tableIcon ms-2"
                  @click="confirmRemoveSchedule(row.item.id)"
                  :icon="['fa', 'trash-alt']" />
              </div>
            </template>
            <template v-else>
              <div class="minW5">
                <font-awesome-icon
                  @click="row.item.editSchedule = false"
                  class="tableIcon me-2 text-primary"
                  :icon="['fas', 'times-circle']" />
                <font-awesome-icon
                  @click="putSchedule(row.item.id, row.item.editSchedule)"
                  class="tableIcon text-primary"
                  :icon="['fas', 'save']" />
              </div>
            </template>
          </div>
        </template>
      </b-table>
    </template>
    <template v-if="addSchedule || schedules.length == 0">
      <div class="d-flex">
        <div class="col-10 titles">Nueva agenda</div>
        <div
          class="
            text-center
            col-2
            text-primary
            align-content-center
            d-flex
            justify-content-center
          ">
          <span
            v-if="addSchedule"
            @click="addSchedule = false"
            class="text-center fw-bold pointer fs-4"
            ><font-awesome-icon :icon="['fas', 'times-circle']"
          /></span>
        </div>
      </div>
      <hr />
      <div class="row justify-content-center">
        <div class="d-flex col-10 align-items-center justify-content-center">
          <span class="col-3 text-primary fw-bold text-center">Nombre</span>
          <span class="col-4 text-primary fw-bold text-center"
            >Tipo de agenda</span
          >
          <span class="col-2 text-primary fw-bold text-center">Color</span>
          <span class="col-3 fs-1 text-primary fw-bold text-center"></span>
        </div>
        <div class="row col-10 align-items-center justify-content-center">
          <div class="col-3">
            <b-form-input
              v-model="name"
              :state="!$v.name.$invalid"
              class="text-primary fw-bold text-center form-control" />
          </div>
          <div class="col-4 text-primary fw-bold text-center">
            <MultiSelect
              class="maxW23"
              :class="[$v.agenda_type.$invalid ? 'warningGeneralBorder' : '']"
              :multiple="true"
              v-model="agenda_type"
              :options="agendaTypes"
              track-by="name"
              label="name"
              value="value"
              placeholder="Agendas" />
          </div>
          <div class="col-2 text-primary fw-bold text-center">
            <v-input-colorpicker v-model="color" class="col-12" />
          </div>
          <div
            class="
              text-center
              col-3
              text-primary
              align-content-center
              d-flex
              justify-content-center
            ">
            <span
              @click="postSchedule"
              class="text-center fw-bold fs-1 pointer pb-3"
              >+</span
            >
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import MultiSelect from "vue-multiselect";
import { mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import { agendaTypes, agendaFields } from "@/constants/settings";

export default {
  name: "SettingsSchedules",
  components: {
    MultiSelect,
  },
  validations() {
    return {
      name: {
        required,
      },
      agenda_type: {
        required,
        minLength: minLength(1),
      },
      color: {
        required,
      },
    };
  },
  data() {
    return {
      schedules: [],
      color: "",
      agenda_type: [],
      name: "",
      agendaTypes,
      addSchedule: false,
      agendaFields,
    };
  },
  created() {
    this.getSchedules();
  },
  methods: {
    ...mapActions(["updateAlerts", "updateConfirmData"]),
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$error) {
        this.updateAlerts({
          status: "warning",
          content: "No debe dejar datos sin llenar.",
        });
      }
      return !this.$v.$error;
    },
    spliceAgendaUsers(agendaUsers) {
      const agendaArrays = [];
      let agendaArray = [];
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < agendaUsers.length; index++) {
        if (index % 3 == 0 && index != 0) {
          agendaArrays.push(agendaArray);
          agendaArray = [];
        }
        agendaArray.push(agendaUsers[index]);
        if (index == agendaUsers.length - 1) {
          agendaArrays.push(agendaArray);
          agendaArray = [];
        }
      }
      return agendaArrays;
    },
    turnValue(valueArray) {
      const arraysTurned = [];
      valueArray.forEach((va) => {
        const agendaUserValue = this.agendaTypes.filter((au) => au.value == va);
        arraysTurned.push(agendaUserValue[0].name);
      });
      return arraysTurned;
    },
    getSchedules() {
      this.$api.settings.getSchedule().then((res) => {
        this.schedules = res.data.agendas;
        this.schedules.forEach((schedule) => {
          this.$set(schedule, "editSchedule", false);
        });
      });
    },
    postSchedule() {
      if (this.isFormDataValid()) {
        this.$api.settings.postSchedule(this.chargedPayload()).then(() => {
          this.getSchedules();
          this.addSchedule = false;
          this.$emit("getSchedules");
        });
      }
    },
    confirmRemoveSchedule(id) {
      this.updateConfirmData({
        title: "Remover agenda",
        description: "¿Esta seguro de querer borrar esta agenda?",
        okText: "Eliminar",
        cancelText: "Cancelar",
        params: id,
        confirmFunction: this.removeSchedule,
      });
    },
    removeSchedule(id) {
      this.$api.settings.removeSchedule(id).then(() => {
        this.getSchedules();
        this.$emit("getSchedules");
      });
    },
    putSchedule(id, editSchedule) {
      if (this.isFormDataValid()) {
        this.$api.settings.putSchedule(id, this.chargedPayload()).then(() => {
          this.getSchedules();
          this.$emit("getSchedules");
        });
        // eslint-disable-next-line no-param-reassign
        editSchedule = false;
      }
    },
    chargedPayload() {
      const agendaUsers = [];
      this.agenda_type.forEach((at) => {
        agendaUsers.push(at.value);
      });
      const payload = {
        name: this.name,
        color: this.color,
        agenda_users: agendaUsers,
      };
      return payload;
    },
    ableEdit(item) {
      this.name = item.name;
      this.color = item.color;
      this.agenda_type = this.agendaTypes.filter((at) =>
        item.agenda_users.includes(at.value)
      );
    },
    ableAdd() {
      this.addSchedule = true;
      this.name = "";
      this.color = "#000000";
      this.agenda_type = [];
    },
  },
};
</script>
