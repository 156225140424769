<template>
  <div class="card py-3 px-4 card-citmer cardRadius">
    <div class="d-flex">
      <div class="col-11 titles">Preferencias de calendario</div>
      <span class="col-1 d-flex justify-content-end">
        <button
          v-if="!preferences"
          @click="preferences = true"
          class="btn m-0 p-0">
          <font-awesome-icon
            class="tableIcon text-primary"
            :icon="['fas', 'edit']" />
        </button>
        <template v-else>
          <button
            @click="
              preferences = false;
              $emit('getCalendarConfig', $store.getters.getClinic);
            "
            class="btn m-0 p-0">
            <font-awesome-icon
              class="tableIcon text-primary"
              :icon="['fas', 'times-circle']" />
          </button>
        </template>
      </span>
    </div>
    <hr />
    <div class="text-secondary my-3 py-3">
      <div class="col-12 row align-items-center justify-content-between">
        <div class="col-xl-3 col-12 mb-xl-0 mb-5">Horario de atención</div>
        <div
          class="
            col-xl-4 col-md-6 col-12
            mb-md-0 mb-3
            d-flex
            align-items-center
          ">
          <div class="col-4"><span>Desde: </span></div>
          <div class="col-8">
            <b-form-select
              :options="hoursListSettings"
              v-model="calendarConfig.hour_ini"
              @change="
                calendarConfig.hour_end = '';
                hoursRest();
              "
              :state="!$v.calendarConfig.hour_ini.$invalid"
              :disabled="!preferences"
              class="
                text-secondary
                rounded
                form-control form-select
                text-center
                radiusButton
              "
              placeholder="Inicio" />
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-12 d-flex align-items-center">
          <div class="col-4">
            <span>Hasta:</span>
          </div>
          <div class="col-8">
            <b-form-select
              :options="optionsRest"
              v-model="calendarConfig.hour_end"
              :disabled="!preferences || $v.calendarConfig.hour_ini.$invalid"
              :state="!$v.calendarConfig.hour_end.$invalid"
              value-field="value"
              text-field="text"
              class="
                text-secondary
                rounded
                form-control form-select
                text-center
                radiusButton
              "
              placeholder="Fin" />
          </div>
        </div>
        <div class="col-12 mt-5 d-flex justify-content-end">
          <template v-if="preferences">
            <button
              @click="
                saveConfig();
                preferences = false;
              "
              :disabled="$v.calendarConfig.$invalid"
              class="btn btn-primary py-3 px-5 radiusButton">
              Guardar
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { hoursListSettings } from "@/constants/settings";
import { required } from "vuelidate/lib/validators";

export default {
  name: "SettingsPreferences",
  props: {
    calendarConfig: {
      type: Object,
      required: false,
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    "calendarConfig.hour_ini": function () {
      this.hoursRest();
    },
  },
  data() {
    return {
      preferences: false,
      hoursListSettings,
      optionsRest: [],
    };
  },
  validations: {
    calendarConfig: {
      hour_ini: {
        required,
      },
      hour_end: {
        required,
      },
    },
  },
  methods: {
    saveConfig() {
      const payload = {
        hour_ini: this.calendarConfig.hour_ini,
        hour_end: this.calendarConfig.hour_end,
      };
      this.$api.calendar.updateConfig(this.$store.getters.getClinic, payload);
    },
    hoursRest() {
      const halfHour = this.calendarConfig.hour_ini.includes("30") ? 1 : 0;
      const hourIni = parseFloat(this.calendarConfig.hour_ini.slice(0, 2), 10);
      let index = halfHour;
      if (hourIni === 1) {
        index += hourIni;
      } else {
        index += hourIni * 2;
        index -= 1;
      }
      const hoursLimit = [];
      // eslint-disable-next-line no-plusplus
      for (index; index <= 46; index++) {
        hoursLimit.push(this.hoursListSettings[index]);
      }
      this.optionsRest = hoursLimit;
    },
  },
};
</script>
