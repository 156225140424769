<template>
  <!-- Modal add new service -->
  <b-modal
    id="addNewService"
    ref="addNewService"
    hide-header
    hide-footer
    size="lg"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true">
    <h4 class="titles">Agregar nuevo servicio</h4>
    <div class="m-3">
      <p>
        Agenda: <b>{{ agenda.name }}</b>
      </p>
      <hr />
      <div class="row">
        <div class="col-md-6 mt-3">
          <label for="name" class="form-label">Nombre del Servicio</label>
          <input id="name" v-model="newService.name" class="form-control" />
        </div>
        <div class="col-md-6 mt-3">
          <label for="duration" class="form-label">Duración</label>
          <select
            v-model="newService.duration"
            class="form-select"
            aria-label=".form-select-lg example">
            <option
              v-for="minutes in minutesList"
              :key="minutes.quantity"
              :value="minutes.quantity"
              v-text="minutes.label"></option>
          </select>
        </div>
        <div class="col-md-6 mt-3">
          <label for="cost" class="form-label">Costo</label>
          <input
            v-model="newService.cost"
            @keypress="isNumber($event)"
            type="number"
            class="form-control" />
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-6">
          <b-btn
            title="Cancelar"
            variant="secondary"
            @click="cancelNewService"
            class="w-100">
            Cancelar
          </b-btn>
        </div>
        <div class="col-6">
          <b-btn
            title="Crear Servicio"
            variant="primary"
            @click="postServices"
            class="w-100">
            Crear Servicio
          </b-btn>
        </div>
      </div>
    </div>
  </b-modal>
  <!-- ----- -->
</template>

<script>
import { minutesList } from "@/constants/settings";
import Form from "@/helpers/form";

export default {
  name: "NewServices",
  props: {
    agenda: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      minutesList,
      schedules: [],
      newService: new Form({
        duration: 5,
        cost: "",
        name: "",
      }),
    };
  },
  mounted() {
    this.$store.commit("newModal", {
      key: "addNewService",
      ref: this.$refs.addNewService,
    });
  },
  methods: {
    cancelNewService() {
      this.newService.reset();
      this.$refs.addNewService.hide();
    },
    isNumber(evt) {
      const event = evt || window.event;
      const charCode = event.which ? event.which : event.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.preventDefault();
      } else {
        return true;
      }
      return "";
    },
    postServices() {
      const payload = this.newService.data();
      payload.agenda_id = this.agenda.id;
      this.$api.settings.postServices(payload).then(() => {
        this.$emit("reloadList");
        this.cancelNewService();
      });
    },
  },
};
</script>

<style></style>
